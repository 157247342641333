import React, { useState, useEffect } from 'react'
import './Banner.scss'
import logo from '../../assets/urbannoir_logo.jpg'
import pic1 from '../../assets/1.jpg'
import pic2 from '../../assets/2.jpg'
import pic3 from '../../assets/3.jpg'
import pic4 from '../../assets/4.jpg'
import pic5 from '../../assets/5.jpg'
import pic6 from '../../assets/6.jpg'
import pic7 from '../../assets/7.jpg'
import pic8 from '../../assets/8.jpg'

const images = [
    logo,
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
  ];

function Banner () {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    useEffect(() => {
    const interval = setInterval(() => {
        // Rotate to the next image
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds
    
    return () => clearInterval(interval); // Clean up the interval on unmount
    }, []);
    
    const backgroundImageStyle = {
        backgroundImage: `url(${images[currentImageIndex]})`,
    };
    
    return <div style={backgroundImageStyle} className="rotating-background"></div>;
    
}

export default Banner

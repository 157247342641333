import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation  } from 'react-router-dom';
import { signOutUserStart } from '../../redux/User/user.actions'

import './Header.scss'
import { logo } from '../../assets/index'


const mapState = (state) => ({
    currentUser: state.user.currentUser,
    // totalNumCartItems: selectCartItemsCount(state)
});

const Header = props => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(false);
    const dispatch = useDispatch();
    const { currentUser, totalNumCartItems } = useSelector(mapState);

    const signOut = () => {
        dispatch(signOutUserStart());
    };

    useEffect(() => {
        setActiveMenu(false);
    }, [location]);

    return (
        <div className='header'>
            <div className='wrap'>
                <div className='logo'>
                    <Link to='/'>
                        <img src={logo} alt='logo' />
                    </Link>
                </div>
                <nav className={`mainMenu ${activeMenu ? 'active' : ''}`}>
                    <div className='callToActions'>
                        <ul>
                            {/* <li>
                                <Link to="/cart">
                                    Cart({totalNumCartItems})
                                    <i class="fas fa-shopping-basket"></i>
                                </Link>
                            </li> */}
                            <li><Link to='/about'>About</Link></li>
                            <li><Link to='/gallery'>Gallery</Link></li>
                            {/* <li><Link to='/udemy'>Udemy</Link></li> */}
                            
                            {/* display different links based on if user is signed in or not */}
                            {currentUser && (
                                <>
                                    <li><Link to='/dashboard'>Account</Link></li>
                                    <li><span onClick={() => signOut()}>Logout</span></li>
                                </>
                            )}

                            {!currentUser && (
                                <><li><Link to='/register'>Register</Link></li><li><Link to='/login'>Login</Link></li></>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

Header.defaultProps = { 
    currentUser: null
}

export default (Header);
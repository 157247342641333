import React, { useEffect } from "react";
import "./ProductCardStyles.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductStart,
  setProduct,
} from "../../redux/Products/products.actions";

const mapState = ({ productsData }) => ({
  product: productsData.product,
});

const ProductCard = ({}) => {
  const dispatch = useDispatch();
  const { productID } = useParams();
  const { product } = useSelector(mapState);

  const {
    productName,
    productThumbnail,
    productWidth,
    productHeight,
    documentID,
    productDesc,
  } = product;

  useEffect(() => {
    dispatch(fetchProductStart(productID));
    return () => {
      dispatch(setProduct({}));
    };
  }, []);

  return (
    <div>
      <h1>{productName}</h1>
      <div className="image-container">
        <img className="thumb" src={productThumbnail} alt="Product Thumbnail" />
      </div>
      <div className="product-info">
        <h2>About the Artwork</h2>
        <p>
          {productWidth} by {productHeight} inches
        </p>
        <p>{productDesc}</p>
      </div>
      <div className="product-price">
        <p>Price: $100</p>
      </div>
    </div>
  );
};

export default ProductCard;

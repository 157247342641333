import React from 'react'
import './Login.scss'
import Signin from '../../components/Signin/Signin'


const Login = () => {
  return (
    <Signin />
  )
}

export default Login
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "./../../components/Forms/FormInput/FormInput";
import FormSelect from "./../../components/Forms/FormSelect/FormSelect";
import Button from "./../../components/Forms/Button/Button";
import { editProductStart } from "../../redux/Products/products.actions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EditProductModal.scss";

const EditProductModal = ({ product, closeModal, onSave }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productName, setProductName] = useState(product.productName || "");
  const [productDesc, setProductDesc] = useState(product.productDesc || "");
  const [productWidth, setProductWidth] = useState(product.productWidth || 0);
  const [productHeight, setProductHeight] = useState(
    product.productHeight || 0
  );
  const [priceGiclee, setPriceGiclee] = useState(product.priceGiclee || 0);
  const [priceCanvas, setPriceCanvas] = useState(product.priceCanvas || 0);
  const [is8by10Checked, setIs8by10Checked] = useState(
    product.is8by10Checked || false
  );
  const [is18by24Checked, setIs18by24Checked] = useState(
    product.is18by24Checked || false
  );
  const [is24by30Checked, setIs24by30Checked] = useState(
    product.is24by30Checked || false
  );
  const [isOriginalSold, setIsOriginalSold] = useState(
    product.isOriginalSold || false
  );

  useEffect(() => {
    setProductName(product.productName || "");
    setProductDesc(product.productDesc || "");
    setProductWidth(product.productWidth || 0);
    setProductHeight(product.productHeight || 0);
    setPriceGiclee(product.priceGiclee || 0);
    setPriceCanvas(product.priceCanvas || 0);
  }, [product]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form...");
    try {
      const updatedProductData = {
        // Update these values with the latest state from the component
        productName,
        productWidth,
        productHeight,
        productDesc,
        priceGiclee,
        priceCanvas,
      };

      console.log("Updated product data:", updatedProductData);

      // Dispatch action with the required payload structure
      console.log("Product ID:", product.documentID);
      dispatch(
        editProductStart({
          documentID: product.documentID,
          updatedProductData,
        })
      );
      console.log("Dispatched edit product action");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    product && (
      <div className="modalOverlay">
        <div className="modalWrap">
          <div className="modal">
            <form onSubmit={handleSubmit}>
              <Button className="closeButton" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <FormInput
                label="Name"
                type="text"
                value={productName}
                handleChange={(e) => setProductName(e.target.value)}
              />

              <FormInput
                label="Description"
                type="text"
                value={productDesc}
                handleChange={(e) => setProductDesc(e.target.value)}
              />

              <FormInput
                label="Width (inches)"
                type="number"
                min="0"
                max="100000"
                step="1"
                value={productWidth}
                handleChange={(e) => setProductWidth(e.target.value)}
              />

              <FormInput
                label="Height (inches)"
                type="number"
                min="0"
                max="100000"
                step="1"
                value={productHeight}
                handleChange={(e) => setProductHeight(e.target.value)}
              />

              <div className="priceField">
                <label>Giclee Price: ${priceGiclee}</label>
                <label>Canvas Price: ${priceCanvas}</label>
              </div>

              <FormInput
                label="Include 8 X 10 Print:"
                type="checkbox"
                checked={is8by10Checked}
                onChange={(e) => setIs8by10Checked(e.target.checked)}
              />

              <FormInput
                label="Include 18 X 24 Print:"
                type="checkbox"
                checked={is18by24Checked}
                onChange={(e) => setIs18by24Checked(e.target.checked)}
              />

              <FormInput
                label="Include 24 X 30 Print:"
                type="checkbox"
                checked={is24by30Checked}
                onChange={(e) => setIs24by30Checked(e.target.checked)}
              />

              <FormInput
                label="Original Not Available:"
                type="checkbox"
                checked={isOriginalSold}
                onChange={(e) => setIsOriginalSold(e.target.checked)}
              />

              <Button type="submit">Save Changes</Button>
              <Button onClick={closeModal}>Cancel</Button>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default EditProductModal;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductStart,
  fetchProductsStart,
  deleteProductStart,
  editProductStart,
} from "./../../redux/Products/products.actions";
import Modal from "./../../components/Modal/Modal";
import EditProductModal from "./../../components/Modal/EditProductModal";
import FormInput from "./../../components/Forms/FormInput/FormInput";
import FormSelect from "../../components/Forms/FormSelect/FormSelect";

import Button from "./../../components/Forms/Button/Button";

import { fbStorage } from "./../../firebase/utils"; // Import the storage object from firebase
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CKEditor } from 'ckeditor4-react';
import "./Admin.scss";

const mapState = ({ productsData }) => ({
  products: productsData.products,
});

const Admin = (props) => {
  const dispatch = useDispatch();
  const { products } = useSelector(mapState);
  const [hideModal, setHideModal] = useState(true);
  const [productCategory, setProductCategory] = useState("");
  const [productName, setProductName] = useState("");
  const [productWidth, setproductWidth] = useState(0);
  const [productHeight, setproductHeight] = useState(0);
  const [productDesc, setProductDesc] = useState("");
  const [selectedThumbImage, setSelectedThumbImage] = useState(null);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [priceGiclee, setPriceGiclee] = useState(0); // Step 1: State for Price
  const [priceCanvas, setPriceCanvas] = useState(0); // Step 1: State for Price
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [is8by10Checked, setIs8by10Checked] = useState(false);
  const [is18by24Checked, setIs18by24Checked] = useState(false);
  const [is24by30Checked, setIs24by30Checked] = useState(false);
  const [isOriginalSold, setIsOriginalSold] = useState(false);

  const openEditModal = (product) => {
    console.log("Opening modal for product:", product);
    setCurrentProduct(product);
    setIsModalOpen(true);
  };

  const handleSaveProduct = (updatedProduct) => {
    // Dispatch an action to update the product
    dispatch(editProductStart(updatedProduct));

    // Close the modal
    setIsModalOpen(false);

    // Refresh the products
    dispatch(fetchProductsStart());
  };
  // Step 2: Calculate Price
  const calculatePrices = () => {
    const pricePerSquareInchGiclee = 1; // Assume $1 per square inch, adjust this value to your pricing model
    const newPriceG = productWidth * productHeight * pricePerSquareInchGiclee;
    setPriceGiclee(newPriceG);
    const pricePerSquareInchCanvas = 2; // Assume $1 per square inch, adjust this value to your pricing model
    const newPriceC = productWidth * productHeight * pricePerSquareInchCanvas;
    setPriceCanvas(newPriceC);
  };

  // Step 3: Effect to Update Price
  useEffect(() => {
    calculatePrices();
  }, [productWidth, productHeight]);

  const { data } = products;

  useEffect(() => {
    dispatch(fetchProductsStart());
  }, []);

  const toggleModal = () => setHideModal(!hideModal);

  const configModal = {
    hideModal,
    toggleModal,
  };

  const resetForm = () => {
    setHideModal(true);
    setProductCategory("");
    setProductName("");
    setSelectedThumbImage("");
    setSelectedMainImage("");
    setproductWidth(0);
    setproductHeight(0);
    setProductDesc("");
    setPriceGiclee(0);
    setPriceCanvas(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedThumbImage && selectedMainImage) {
      // Create a reference to the storage location where you want to store the image
      const storageRef1 = fbStorage
        .ref()
        .child("images/" + selectedThumbImage.name);
      const storageRef2 = fbStorage
        .ref()
        .child("images/" + selectedMainImage.name);
      try {
        // Upload the image file to Firebase Storage
        await storageRef1.put(selectedThumbImage);
        await storageRef2.put(selectedMainImage);
        // Get the download URL of the uploaded image
        const downloadURL1 = await storageRef1.getDownloadURL();
        const downloadURL2 = await storageRef2.getDownloadURL();
        // Now, you can use the downloadURL to display the image in your product modal

        // Add the rest of your product data to Firestore, including the image URL
        dispatch(
          addProductStart({
            productCategory,
            productName,
            productThumbnail: downloadURL1,
            productImage: downloadURL2,
            productWidth,
            productHeight,
            productDesc,
            priceGiclee,
            priceCanvas,
            is8by10Checked,
            is18by24Checked,
            is24by30Checked,
            isOriginalSold,
          })
        );

        resetForm();
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      // Handle the case where no image is selected
    }
  };

  return (
    <div className="admin">
      <div className="callToActions">
        <ul>
          <li>
            <Button onClick={() => toggleModal()}>Add new product</Button>
          </li>
        </ul>
      </div>

      <Modal {...configModal}>
        <div className="addNewProductForm">
          <form onSubmit={handleSubmit}>
            <h2>Add new product</h2>

            <FormInput
              label="Name"
              type="text"
              value={productName}
              handleChange={(e) => setProductName(e.target.value)}
            />

            <FormSelect
              label="Category"
              options={[
                {
                  value: "Abstract",
                  name: "Abstract",
                },
                {
                  value: "Figurative",
                  name: "Figurative",
                },
                {
                  value: "Landscape",
                  name: "Landscape",
                },
                {
                  value: "Portrait",
                  name: "Portrait",
                },
                {
                  value: "Still Life",
                  name: "Still Life",
                },
              ]}
              handleChange={(e) => setProductCategory(e.target.value)}
            />

            <FormInput
              label="Description"
              type="text"
              value={productDesc}
              handleChange={(e) => setProductDesc(e.target.value)}
            />

            <FormInput
              label="Thumbnail image upload Web Resolution (JPEG)"
              type="file"
              accept="image/*"
              onChange={(e) => setSelectedThumbImage(e.target.files[0])}
            />

            <FormInput
              label="Main image upload High Resolution (TIF)"
              type="file"
              accept="image/*"
              onChange={(e) => setSelectedMainImage(e.target.files[0])}
            />

            <FormInput
              label="Width (inches)"
              type="number"
              min="0"
              max="100000"
              step="1"
              value={productWidth}
              handleChange={(e) => setproductWidth(e.target.value)}
            />

            <FormInput
              label="Height (inches)"
              type="number"
              min="0"
              max="100000"
              step="1"
              value={productHeight}
              handleChange={(e) => setproductHeight(e.target.value)}
            />

            <div className="priceField">
              <label>Giclee Price: ${priceGiclee}</label>{" "}
              {/* Step 4: Display Price in Modal */}
              <label>Canvas Price: ${priceCanvas}</label>{" "}
              {/* Step 4: Display Price in Modal */}
            </div>

            <FormInput
              label="Include 8 X 10 Print:"
              type="checkbox"
              checked={is8by10Checked}
              onChange={(e) => setIs8by10Checked(e.target.checked)}
            />

            <FormInput
              label="Include 18 X 24 Print:"
              type="checkbox"
              checked={is18by24Checked}
              onChange={(e) => setIs18by24Checked(e.target.checked)}
            />

            <FormInput
              label="Include 24 X 30 Print:"
              type="checkbox"
              checked={is24by30Checked}
              onChange={(e) => setIs24by30Checked(e.target.checked)}
            />

            <FormInput
              label="Original Not Available:"
              type="checkbox"
              checked={isOriginalSold}
              onChange={(e) => setIsOriginalSold(e.target.checked)}
            />

            <br />

            <Button type="submit">Add product</Button>
          </form>
        </div>
      </Modal>

      <div className="manageProducts">
        <h1>Manage Products</h1>
        <table className="results" border="0" cellPadding="10" cellSpacing="0">
          <tbody>
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((product, index) => {
                const {
                  productName,
                  productThumbnail,
                  productWidth,
                  productHeight,
                  documentID,
                } = product;

                return (
                  <tr key={documentID}>
                    <td>
                      <img
                        className="thumb"
                        src={productThumbnail}
                        alt="Product Thumbnail"
                      />
                    </td>
                    <td>{productName}</td>
                    <td>{productWidth} in</td>
                    <td>{productHeight} in</td>
                    <td>
                      <Button
                        onClick={() => dispatch(deleteProductStart(documentID))}
                      >
                        Delete
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => openEditModal(product)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <EditProductModal
          product={currentProduct}
          closeModal={() => setIsModalOpen(false)}
          onSave={handleSaveProduct}
        />
      )}
    </div>
  );
};

export default Admin;

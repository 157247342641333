import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetPasswordStart, resetUserState } from "./../../redux/User/user.actions";
import { selectResetPasswordSuccess, selectUserErr } from "./../../redux/User/userSelectors";
import "./EmailPassword.scss";

import AuthWrapper from "./../AuthWrapper/AuthWrapper";
import FormInput from "../Forms/FormInput/FormInput";
import Button from "../Forms/Button/Button";

const EmailPassword = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  // Using memoized selectors
  const resetPasswordSuccess = useSelector(selectResetPasswordSuccess);
  const userErr = useSelector(selectUserErr);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState());
      history("/login");
    }
  }, [resetPasswordSuccess]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPasswordStart({ email }));
  };

  const configAuthWrapper = {
    headline: "Email Password",
  };

  return (
    <AuthWrapper {...configAuthWrapper}>
      <div className="formWrap">
        {errors.length > 0 && (
          <ul>
            {errors.map((e, index) => {
              return <li key={index}>{e}</li>;
            })}
          </ul>
        )}

        <form onSubmit={handleSubmit}>
          <FormInput
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            handleChange={(e) => setEmail(e.target.value)}
          />

          <Button type="submit">Email Password</Button>
        </form>

        <div className="links">
          <Link to="/login">LogIn</Link>
          <pre className="spacer">|</pre>
          <Link to="/register">Register</Link>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default EmailPassword;

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import firebaseConfig from './config';
import { storage } from 'firebase';


firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const fbStorage = firebase.storage(); 

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: 'select_account' });

export const handleUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return;
  const { uid } = userAuth;

  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { displayName, email } = userAuth;
    const timestamp = new Date();
    const userRoles = ['user'];

    try {
      await userRef.set({
        displayName,
        email,
        createdDate: timestamp,
        userRoles,
        ...additionalData
      });
    } catch(err) {
      // console.log(err);
    }
  }

  return userRef;
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  })
}


// Function to fetch a list of files from a specified storage bucket folder
export const fetchImagesFromStorage = async (folderName) => {
  const storageRef = fbStorage.ref().child(folderName);
  const images = [];

  try {
    const listResult = await storageRef.listAll();

    for (const item of listResult.items) {
      const downloadURL = await item.getDownloadURL();
      images.push({ name: item.name, url: downloadURL });
    }
  } catch (error) {
    console.error('Error fetching images from storage:', error);
  }

  return images;
};
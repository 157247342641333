import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectResetPasswordSuccess = createSelector(
  [selectUser],
  user => user.resetPasswordSuccess
);

export const selectUserErr = createSelector(
  [selectUser],
  user => user.userErr
);

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user.currentUser
);
import React from "react";
import Signup from "../../components/Signup/Signup";

const Register = () => {
  return (
    <div>
      <div className="message">
        <h1>Register for updates on new art and events!</h1>
        <h1>Shop and Udemy class Coming soon!</h1>
      </div>
      <Signup />
    </div>
  );
};

export default Register;

import React from "react";
import ProductCard from "../../components/ProductCard/ProductCard";

export const ProductDetails = () => {
  return (
    <div>
      <ProductCard />
    </div>
  );
};

export default ProductDetails;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signUpUserStart } from './../../redux/User/user.actions';
import { selectCurrentUser, selectUserErr } from "./../../redux/User/userSelectors";

import './Signup.scss';

import AuthWrapper from './../AuthWrapper/AuthWrapper';
import FormInput from './../Forms/FormInput/FormInput';
import Button from './../Forms/Button/Button';

const Signup = props => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { currentUser, userErr } = useSelector(state => ({
      currentUser: selectCurrentUser(state),
      userErr: selectUserErr(state),
    }));
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);

    useEffect(() => {
      if (currentUser) {
        reset();
        history('/');
      }
    }, [currentUser]);

    useEffect(() => {
      if (Array.isArray(userErr) && userErr.length > 0) {
        setErrors(userErr);
      }

    }, [userErr]);

    const reset = () => {
      setDisplayName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setErrors([]);
    };

    const handleFormSubmit = event => {
      event.preventDefault();
      dispatch(signUpUserStart({
        displayName,
        email,
        password,
        confirmPassword
      }));
    }

    const configAuthWrapper = {
      headline: 'Registration'
    };

    return (
      <AuthWrapper {...configAuthWrapper}>
        <div className="formWrap">

          {errors.length > 0 && (
            <ul>
              {errors.map((err, index) => {
                return (
                  <li key={index}>
                    {err}
                  </li>
                );
              })}
            </ul>
          )}

          <form onSubmit={handleFormSubmit}>

            <FormInput
              type="text"
              name="displayName"
              value={displayName}
              placeholder="Full name"
              handleChange={e => setDisplayName(e.target.value)}
            />

            <FormInput
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              handleChange={e => setEmail(e.target.value)}
            />

            <FormInput
              type="password"
              name="password"
              value={password}
              placeholder="Password"
              handleChange={e => setPassword(e.target.value)}
            />

            <FormInput
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              placeholder="Confirm Password"
              handleChange={e => setConfirmPassword(e.target.value)}
            />

            <Button type="submit">
              Register
            </Button>
          </form>

          <div className="links">
            <Link to="/login">
              LogIn
            </Link>
            <pre className="spacer">|</pre>
            <Link to="/recovery">
              Reset Password
              </Link>
          </div>
        </div>
      </AuthWrapper>
    );
}

export default Signup;
import React, { useState, useEffect, useRef } from "react";
import "./Gallery.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsStart } from "./../../redux/Products/products.actions";
import { Link } from "react-router-dom";

const mapState = ({ productsData }) => ({
  products: productsData.products,
});

const Gallery = () => {
  const dispatch = useDispatch();
  const { products } = useSelector(mapState);
  const galleryRef = useRef(null);

  const { data, queryDoc } = products;

  useEffect(() => {
    dispatch(fetchProductsStart());
  }, []);

  const handleLoadMore = () => {
    dispatch(
      fetchProductsStart({
        startAfterDoc: queryDoc,
        persistProducts: data,
      })
    );
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  return (
    <div className="gallery">
      <h2>Gallery</h2>
      <div className="gallery-container" ref={galleryRef}>
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map((product, index) => {
            const {
              productName,
              productThumbnail,
              productWidth,
              productHeight,
              documentID,
              priceGiclee,
              priceCanvas,
            } = product;

            return (
              <div className="product-card" key={documentID}>
                {" "}
                {/* Add product-card class */}
                <div className="product-image">
                  <Link to={`/products/${documentID}`}>
                    <img
                      className="thumb"
                      src={productThumbnail}
                      alt="Product Thumbnail"
                    />
                  </Link>
                </div>
                <div className="product-info">
                  <Link to={`/products/${documentID}`}>
                    <h3>{productName}</h3>
                  </Link>
                  <p>
                    Dimensions: {productWidth}" x {productHeight}"
                  </p>

                  <p>${priceCanvas} Original</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Gallery;

import React from 'react'
import Banner from '../../components/Banner/Banner'

import './Home.scss'

const Home = () => {
  return (
    <div className='wrapper'>
      <div className="content-container">
        <Banner />
      </div>
    </div>
  )
}

export default Home;
import React from 'react'
import pic1 from '../../assets/1.jpg'

import './About.scss'; // Import your CSS file

const About = () => {
  return (
    <div className='wrapper'>
      <div className="content-container">
        <h1>About Urban Noir</h1>
        <div className="grid-container">
          <div className='bio-text-container'>
            <p className='bio'> Chris Rock once said that when you have a job, <br></br>
                there are too many hours in the day, but when <br></br>
                you have a career, there are not enough hours in the day.
            </p>
            <br></br><br></br> 
            <p className='bio'>This is how I feel about painting.
            </p> 
            <br></br><br></br>
            <p className='bio'> After having many jobs that people would call a career, hating them and counting every hour, I 
                finally found what I was put on the earth to do.  And that is to paint.   I strive to create an 
                atmosphere of strong contrasting light, framed in a background of gritty urban scenes I like to 
                call Urban Noir.  I’ve found that the most important part of painting is making the viewer 
                believe that the light your portraying is real.   I strive to take snapshots of my life and put the 
                viewer in that moment.  And I want the viewer to feel emotion.  The greater the degree of 
                feeling I create, the more successful I feel the painting is.
            </p>
            <br></br><br></br>      
            <p className='bio'> I was born in Seattle and spent most of my life here.  I got my start in art doing cartoons and 
                briefly drew a comic strip for the Cooper Point Journal at The Evergreen State College.  I then
                went to Americorps for a year and finished up schooling at Washington State University.   I was
                also in the Coast Guard and used my GI Bill to begin work on my masters at San Diego State
                University, but never finished.  I slowly became less and less involved in Art.  But having
                suffered some major losses involving family members, I began using art to as a way to release
                pain.  It worked very well, and I painted and painted until I realized that I was obsessed with
                painting, but felt that I didn’t have the skills to truly express myself.  It was when I decided to go
                to the Gage Academy of Art on Capitol Hill.  I called and found that the classical program was
                filled and I had waited too long (school was just about to start).  I had heard of the MKOH (Mark
                Kang‐O'Higgins) atelier,  a different program with less emphasis on classical studies and more
                on modern art,  and to my surprise, one spot was still available.  Perfect right?  I joined and
                spent three years learning that I knew absolutely nothing about art.  But I learned the skills that
                I needed to begin to learn.  And I am happy to say that art is the greatest endeavor, next to
                being a father, that I could possibly take on.
            </p> 
            <br></br><br></br>
            <p className='bio'>I’ve never looked back.</p>
          </div>
          <div className='bio-image-container'>
            <img src={pic1} alt='blue' />
          </div>
        </div>
      </div>
    </div>
  );
}


export default About;
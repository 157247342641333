import React from 'react'

const Footer = () => {
  return (
    <div className='footer-container'>
        <div className='wrapper'>
            <p>© 2023 Urban Noir LLC</p>
            <div className='footer-social'>
              <a href='https://www.instagram.com/urbannoirartist/' target='_blank' rel='noopener noreferrer'><i className='fa fa-instagram'></i></a>
              <a href='https://www.facebook.com/profile.php?id=61550974651178' target='_blank' rel='noopener noreferrer'><i className='fa fa-facebook'></i></a>
            </div>
        </div>
    </div>
  )
}

export default Footer
// Desc: Main App component
// Imports:
//  React: Package to build react component interface
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import the Routes component
import { checkUserSession } from "./redux/User/user.actions";

// Import layouts
import HomepageLayout from "./Layouts/HomepageLayout";
import MainLayout from "./Layouts/MainLayout";
import AdminLayout from "./Layouts/AdminLayout";
import DashboardLayout from "./Layouts/DashboardLayout";

// Import Pages
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Gallerypage from "./Pages/Gallery/Gallerypage";
import Udemy from "./Pages/Udemy/Udemy";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Recovery from "./Pages/Recovery/Recovery";
import Cart from "./Pages/Cart/Cart";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Admin from "./Pages/Admin/Admin";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";

// Import HOC
import WithAuth from "./hoc/withAuth";
import WithAdminAuth from "./hoc/withAdminAuth";
import AdminToolbar from "./components/AdminToolbar/AdminToolbar";

const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, []);

  return (
    <div className="App">
      <AdminToolbar />
      <Routes>
        {/* Hompage Layout section */}
        {/* Route for the root path */}
        <Route
          exact
          path="/"
          element={
            <HomepageLayout>
              <Home />
            </HomepageLayout>
          }
        />

        {/* Main Layout section no auth needed*/}
        {/* Route for the about path */}
        <Route
          path="/about"
          element={
            <MainLayout>
              <About />
            </MainLayout>
          }
        />
        {/* Route for the gallery path */}
        <Route
          path="/gallery"
          element={
            <MainLayout>
              <Gallerypage />
            </MainLayout>
          }
        />
        {/* Route for the udemy path */}
        <Route
          path="/udemy"
          element={
            <MainLayout>
              <Udemy />
            </MainLayout>
          }
        />
        {/* Route for the product details path */}
        <Route
          path="/products/:productID"
          element={
            <MainLayout>
              <ProductDetails />
            </MainLayout>
          }
        />
        {/* Route for the register path */}
        <Route
          path="/register"
          element={
            <MainLayout>
              <Register />
            </MainLayout>
          }
        />
        {/* Route for the login path */}
        <Route
          path="/login"
          element={
            <MainLayout>
              <Login />
            </MainLayout>
          }
        />
        {/* Route for the recovery path */}
        <Route
          path="/recovery"
          element={
            <MainLayout>
              <Recovery />
            </MainLayout>
          }
        />
        {/* Route for the cart path */}
        <Route
          path="/cart"
          element={
            <MainLayout>
              <Cart />
            </MainLayout>
          }
        />

        {/* Dashboard Layout section Auth needed */}
        {/* Route for the dashboard path */}
        <Route
          path="/dashboard"
          element={
            <WithAuth>
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            </WithAuth>
          }
        />

        {/* Admin Layout section ADMIN Auth needed */}
        {/* Route for the admin path */}
        <Route
          path="/admin"
          element={
            <WithAdminAuth>
              <AdminLayout>
                <Admin />
              </AdminLayout>
            </WithAdminAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
